import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import './App.css';

function App() {
  const backendaddr = 'https://pygwija7a6x4kkiffeqoidmcou0wxjrn.lambda-url.ap-northeast-2.on.aws';
  const [videoUrl, setVideoUrl] = useState('');
  const [hashtags, setHashtags] = useState('');
  const [title, setTitle] = useState('');
  const [count, setCount] = useState('');
  const [selectedComments, setSelectedComments] = useState([]);
  const [totalCommentsCount, setTotalCommentsCount] = useState(0);
  const [activeTab, setActiveTab] = useState('pick');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [logs, setLogs] = useState([]);
  const [skip, setSkip] = useState(0);
  const [hasMoreLogs, setHasMoreLogs] = useState(true);
  const [loadingLogs, setLoadingLogs] = useState(false);
  
  


  const fetchLogs = async () => {
    setLoadingLogs(true); // 로딩 시작
    try {
      const response = await axios.get(`${backendaddr}/logs`, {
        params: {limit:10, skip}
      });
      if(response.data.length === 0){
        setHasMoreLogs(false); // 로그 없음
      } else{
        setLogs(prevLogs => [...prevLogs, ...response.data]); // 로그 데이터를 상태에 저장
        setSkip(prevSkip => prevSkip + 10); // 로그 넘버 기억
      }
    } catch (error) {
      console.error('로그를 불러오는데 실패했습니다:', error);
    } finally {
      setLoadingLogs(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'log') {
      fetchLogs(); // "Log" 탭이 활성화되면 로그 데이터를 불러옴
    }
  }, [activeTab]);

  const handleSubmit = async () => {

    // 입력값 유효성 검사
    if (!videoUrl.trim() || !hashtags.trim() || !title.trim() || count < 1) {
      setErrorMessage("모든 필드가 입력 되고, 추첨 인원 수는 최소 1 이상이어야 합니다..");
      return; 
    } else {
      setErrorMessage(''); // 입력값이 유효하면 경고 메시지를 비움
    }

    setLoading(true);

    try {
      const response = await axios.post(`${backendaddr}/pick`, {
        title,
        videoUrl,
        hashtags,
        count: parseInt(count, 10)
      });

      setSelectedComments(response.data.selectedComments);
      setTotalCommentsCount(response.data.totalCommentsCount);
    } catch (error) {
      console.error('Error sending data:', error);
      setSelectedComments([]);
      setTotalCommentsCount(0);
    } finally{
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <h1>감정이 없는 추첨기</h1>

      <div className="tabs">
        <button onClick={() => setActiveTab('pick')} className={activeTab === 'pick' ? 'active-tab' : ''}>Pick</button>
        <button onClick={() => setActiveTab('log')} className={activeTab === 'log' ? 'active-tab' : ''}>Log</button>
      </div>
      
      {activeTab === 'pick' && (
        <div className="tab-pick"> 
          <p className="draw-notice">5분에 3번 & 20초에 1번 추첨을 진행할 수 있습니다.</p>
          <br></br>
          <div className="input-group">
            <label htmlFor="videoUrl">영상 주소:</label>
            <input id="videoUrl" type="text" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
          </div>
          <div className="input-group">
            <label htmlFor="hashtags">포함시킬 해시태그:</label>
            <input id="hashtags" type="text" value={hashtags} onChange={(e) => setHashtags(e.target.value)} />
          </div>
          <div className="input-group">
            <label htmlFor="title">뽑기 제목:</label>
            <input id="title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="input-group">
            <label htmlFor="count">몇 개 뽑을지:</label>
            <input id="count" type="number" value={count} onChange={(e) => setCount(e.target.value)} />
          </div>
          
          {/* 로딩 중일 때 스피너, 아닐 때 뽑기 버튼*/}

          {loading ?(
            <div className="spinner"> </div>
          ) : (
            <button onClick={handleSubmit}>뽑기</button>
          )}
          

          {errorMessage && <div className="error-message"><b> {errorMessage}</b></div>}

          {selectedComments.length > 0 && (
            <div className='results'>
              <div className='title'> <h1>{title}</h1> </div>
              <div className='count'> <h4>('{hashtags}' 가 포함된 {totalCommentsCount}개 댓글 중 {selectedComments.length}개 선택)</h4> </div>

              {selectedComments.map((comment, index) => (
                <div key={index} className='comment'>
                  <p><strong>{comment.author}</strong>: {comment.comment}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}


      {activeTab === 'log' && (
        <div className="tab-log">
          {/* 로그가 로딩 중일 때 테이블이 비어있으면 스피너 표시 */}
          {logs.length === 0 && loadingLogs ? (
            <div className="spinner"></div>  // 첫 로딩 시 스피너
          ) : (
            <>
              {/* 로그가 이미 로드되었을 경우 테이블 표시 */}
              <table>
                <thead>
                  <tr>
                    <th>IP</th>
                    <th>제목</th>
                    <th>시간</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log, index) => (
                    <tr key={index}>
                      <td>{log.pickIP.includes(':') ? `${log.pickIP.substring(0, 8)}...` : log.pickIP}</td>
                      <td>{log.pickName}</td>
                      <td>{moment(log.pickDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* 더보기 버튼이 스피너로 바뀜 */}
              <button
                onClick={fetchLogs}
                className="load-more-btn"
                disabled={loadingLogs || !hasMoreLogs}  // 로딩 중 또는 더 가져올 로그가 없으면 비활성화
              >
                {loadingLogs ? <div className="spinner"></div> : '더보기'}
              </button>
            </>
          )}
        </div>
      )}

    </div>
  );
}

export default App;
